import {Item} from "../model/Item";
import {Category} from "../model/Category";

const categories = [
    new Category("Öl", [
        new Item("Guldkällan", 20, "7310401013114"),
        new Item("Innis & Gunn", 30, "2"),
        new Item("Laguinitas", 30, "3"),
        new Item("Omnipollo", 65, "1"),
    ]),
    new Category("Cider", [
        new Item("Sommersby", 20, "7"),
        new Item("Alkfri cider", 20, "13"),
    ]),
    new Category("Vin", [
        new Item("Rött vin", 25, "4"),
        new Item("Vitt vin", 25, "5"),
        new Item("Mousserande vin", 25, "6"),
    ]),
    new Category("Läsk", [
        new Item("Trocadero", 15, "14"),
        new Item("Cola Zero", 15, "15"),
    ]),
    new Category("Tilltugg", [
        new Item("Ölkorv", 15, "8"),
        new Item("Toast", 15, "9"),
        new Item("Ostbågar", 15, "10"),
        new Item("Chips", 15, "11"),
        new Item("Gott & Blandat", 20, "12"),
    ]),
    new Category("Rabatt", [
        new Item("Rabatt", -20, "61"),
        new Item("Rabatt", -5, "62"),
    ]),
];

export default categories;
