import {Item} from "./Item";

class Category {

    private readonly _name: string;
    private readonly _items: Item[];

    constructor(name: string, items: Item[]) {
        this._name = name;
        this._items = items;
    }

    get name(): string {
        return this._name;
    }

    get items(): Item[] {
        return this._items;
    }

}

export {Category};
