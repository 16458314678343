class Item {

    private readonly _name: string;
    private readonly _price: number;
    private readonly _code: string;

    constructor(name: string, price: number, code: string) {
        this._name = name;
        this._price = price;
        this._code = code;
    }

    get name(): string {
        return this._name;
    }

    get price(): number {
        return this._price;
    }

    get code(): string {
        return this._code;
    }

}

export {Item};
